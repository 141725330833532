<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="!ready" />
        <div v-if="ready">
          <crud-list
            v-model="administradores"
            :headers="headers"
            :slots="['item.telefones']"
            :actions="actions"
            :sort-by="['nome']"
            :sort-desc="[false]"
            :export-pdf-xlsx="true"
          >
            <template v-slot:btnCadastro>
              <v-col>
                <v-btn
                  class="text-none text-white"
                  block
                  rounded
                  color="primary"
                  title="Cadastrar novo administrador"
                  @click="$router.push({ path: 'administradores/cadastrar' })"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Cadastrar
                </v-btn>
              </v-col>
            </template>
            <template v-slot:[`item.telefones`]="{ item }">
              <div
                v-for="(telefone, index) in item.telefones"
                :key="index"
              >
                <span v-if="telefone">
                  {{ `${index + 1}:  ${telefone}` }}
                  <br>
                </span>
              </div>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import AdministradoresStore, { BOOTSTRAP, DELETE_ADMIN } from '@/store/modules/administradores'
  import { mapState, mapActions } from 'vuex'
  import { sortDateTime } from '../../utils/sorts'

  export default {
    data () {
      return {
        search: '',
        actions: [
          {
            title: 'Editar Administrador',
            color: 'info darken-3',
            click: item => this.editarAdministrador(item),
            icon: 'mdi-pencil',
          },
          {
            title: 'Excluir Administrador',
            color: 'red darken-3',
            click: item => this.DELETE_ADMIN(item),
            icon: 'mdi-trash-can-outline',
          },
        ],
        headers: [
          { text: '', value: 'actions', sortable: false, groupable: false, width: '20px' },
          { align: 'left', sortable: true, groupable: false, text: 'Nome', value: 'nome', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'CPF', value: 'cpf', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: true, text: 'Papel', value: 'roles.description', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Email', value: 'email', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'RG', value: 'rg', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Data de Nascimento', value: 'data_nascimento', width: 'auto', sort: sortDateTime },
          { align: 'left', sortable: true, groupable: false, text: 'Sexo', value: 'sexo', width: 'auto' },
          { align: 'left', sortable: false, groupable: false, text: 'Telefones', value: 'telefones', width: 'auto', report: true },
        ],
      }
    },
    computed: {
      ...mapState('administradores', ['ready', 'administradores']),
    },
    async created () {
      if (!this.$store.hasModule('administradores')) { this.$store.registerModule('administradores', AdministradoresStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('administradores', [BOOTSTRAP, DELETE_ADMIN]),
      editarAdministrador ({ id }) {
        this.$router.push({
          path: `/${this.$user.get().role}/administradores/alterar`,
          query: { id },
        })
      },
    },
  }
</script>
